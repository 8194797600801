import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <div className="contentWrapper">
      <section className="container404 innerSection">
        <div className="wrapper">
          <h2 className="mainHeading">404</h2>
          <p>The page you are looking for does not exist.</p>
          <a href="/" className="btnStyle1">Back to Homepage</a>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
